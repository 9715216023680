import './App.css';
import Header from "./components/Header/";
import Swap from "./components/Swap/";
import Token from "./components/Token/";
import ComingSoon from "./components/Coming/";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="tcd-main-content">
        <Routes>
          <Route path="/" element={<Swap />} />
          <Route path="/token" element={<Token />} />
          <Route path="/pool" element={<ComingSoon />} />
          <Route path="*" element={<Swap />} />
        </Routes>
      </div>

    </div>
  );
}

export default App;
