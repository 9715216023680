import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import Eth from "../../images/eth.svg";
import { Link } from "react-router-dom";
import './index.css';

function Header(props) {
  const isMobileScreen = window.screen.width < 768 || window.innerWidth < 768;
  const [active, setActive] = useState(1);

  const address = '';
  const isConnected = false;

  return (
    <header>
      <a href="/">
        <img src={Logo} alt="logo" className="logo" />
      </a>
      <div className={`tcd-main-menu ${isMobileScreen ? 'tcd-menu-fixed--bottom' : ''}`}>
        <Link to="/" className={`link ${active === 1 ? 'active' : ''}`} onClick={() => setActive(1)}>
          <div className="headerItem">Swap</div>
        </Link>
        <Link to="/token" className={`link ${active === 2 ? 'active' : ''}`} onClick={() => setActive(2)}>
          <div className="headerItem">Tokens</div>
        </Link>
        <Link to="/pool" className={`link ${active === 3 ? 'active' : ''}`} onClick={() => setActive(3)}>
          <div className="headerItem">Pool</div>
        </Link>
      </div>
      <div className="tcd-header-right">
        <div className="tcd-chains">
          <img src={Eth} alt="eth" className="eth" />
          Ethereum
        </div>
        {/* onClick={connect} */}
        {/* <div className="tcd-connectButton" >
          {isConnected ? (address.slice(0,4) +"..." +address.slice(38)) : "Connect"}
        </div> */}
      </div>
    </header>
  );
}

export default Header;
