import './index.css';
import { SwapWidget, darkTheme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'

// const customTokenList = [
//   {
//   chainId: 5,
//   name: "HDAOTESTNET",
//   address: "0x74F8dfF5deC8880E2Ef9000dCC0eD66F7cd28d77",
//   decimals: 18,
//   symbol: "HDAO",
//   logoURI: "https://s2.coinmarketcap.com/static/img/coins/64x64/5.png"
//   }
// ]

// Use the native token of the connected chain as the default input token
const NATIVE = 'NATIVE' // Special address for native token

function Swap() {
  return (
    <div className="tcd-swap">
      <SwapWidget
        // theme={darkTheme}
        // tokenList={customTokenList}
        defaultInputTokenAddress={NATIVE}
        // const defaultOutputTokenAddress={'0x74F8dfF5deC8880E2Ef9000dCC0eD66F7cd28d77'}
      />
    </div>
  );
}

export default Swap;
