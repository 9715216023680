import './index.css';

function ComingSoon() {
  return (
    <div className="coming-soon">
      Coming soon
    </div>
  );
}

export default ComingSoon;
